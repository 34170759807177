import { useEffect, useState } from 'react'
/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

export type BlockData = {
  name: string
  description: string | null
  start_time: string | null
  finish_time: string | null
  recurrent_finish_time: string | null
  recurrent_start_time: string | null
  specialists: Array<{
    id: number
  }>
  week_day: string | null
  expand_recurrent_days: boolean
  is_recurrent: boolean
}

export type SpecialistsData = {
  id: number
  social_name: string
  user: {
    id: number
    name: string
    email: string
    contact: string
  }
}

const BASE_URL = 'clinics/block-times/'

export const useService = () => {
  const [loadingRest, setLoadingRest] = useState(false)
  const [specialistsEdit, setSpecialistsEdit] = useState<SpecialistsData[]>([])

  // terceiros
  const router = useHistory()
  const toast = useToast()

  useEffect(() => {
    const getSpecialistsEdit = () => {
      try {
        apiAuth
          .get(`users/attendances/`, {
            params: {
              user__is_active: true,
              is_specialist: true,
              permission_type: '3'
            }
          })
          .then((response) => {
            setSpecialistsEdit(response.data.results)
          })
      } catch (err: any) {
        console.log(err)
      }
    }

    getSpecialistsEdit()
  }, [])

  // função para criar
  async function createRequest(body: BlockData) {
    try {
      const response = await apiAuth.post(BASE_URL, body)

      if (response.data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/settings/block-times')
        }, 2000)
      }
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // função para editar
  async function updateRequest(body: BlockData, id: number) {
    try {
      setLoadingRest(true)
      const res = await apiAuth.put(`${BASE_URL}${id}/`, body)

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/settings/block-times')
      }, 2000)
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // export
  return {
    createRequest,
    updateRequest,
    loadingRest,
    specialistsEdit
  }
}
