/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'
import { queryClient } from '../../services/reactQuery'

export type ServicesData = {
  id: number
  name: string
  service_sessions: Array<{
    id: number
    duration: number
    time_to_be_there: number
    session_number: number
    service_id: number
  }>
}

export type SpotsData = {
  id: number
  name: string
  is_default: boolean
}

export type TagsData = {
  id: number
  name: string
}

export type ClientsData = {
  id: number
  name: string
}

export type SpecialistsData = {
  id: number
  social_name: string
  user: {
    id: number
    name: string
    email: string
    contact: string
  }
}

export const useService = () => {
  const [isLoadingSpecialists, setIsLoadingSpecialists] = useState(false)
  const [specialists, setSpecialists] = useState<SpecialistsData[]>([])

  useEffect(() => {
    // get infos by scheduling

    const getSpecialists = () => {
      try {
        setIsLoadingSpecialists(true)
        apiAuth
          .get(`users/attendances/`, {
            params: {
              user__is_active: true,
              is_specialist: true,
              permission_type: '2'
            }
          })
          .then((response) => {
            setSpecialists(response.data.results)
            setIsLoadingSpecialists(false)
          })
      } catch (err: any) {
        console.log(err)
        setIsLoadingSpecialists(false)
      }
    }

    getSpecialists()

    return () => {}
  }, [])

  return {
    isLoadingSpecialists,
    specialists
  }
}
