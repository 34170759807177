import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'

interface ModalConfirmExcludeProps {
  children: ReactNode
  title: string
  onSave?: () => Promise<void> | void
  outNotSave?: () => Promise<void> | void
  onConfirmExclude: () => Promise<void> | void
  isOpen: boolean
  isLoadingOnSave?: boolean
  onClose: () => void
  isButtonOff?: boolean
}

export const ModalConfirmExclude = ({
  children,
  title,
  onConfirmExclude,
  onSave,
  isOpen,
  outNotSave,
  onClose,
  isLoadingOnSave = false,
  isButtonOff = false
}: ModalConfirmExcludeProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isWideVersion ? 'md' : 'sm'}
        blockScrollOnMount={false}
        key={title}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            {!isButtonOff ? (
              <>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme="red" onClick={onConfirmExclude}>
                  Confirmar
                </Button>
              </>
            ) : (
              <>
                <Button colorScheme="red" mr={3} onClick={outNotSave}>
                  Sair sem salvar
                </Button>
                <Button
                  isLoading={isLoadingOnSave}
                  colorScheme="blue"
                  onClick={onSave}
                >
                  Salvar e sair
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
