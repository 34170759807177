/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  useBreakpointValue,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Switch } from '../../components/Form/Switch'
import { useService } from './service'
import { InputCurrency } from '../../components/Form/InputCurrency'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { PermissionComponent } from '../../components/Permissions'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { Select } from '../../components/Form/Select'

type FormData = {
  description: string
  repeat: boolean
}

type SelectType = {
  label: string
  value: number
}

type SelectTypeWeek = {
  label: string
  value: string
}

export function CreateOrUpdateBlockScheduling() {
  const [t] = useTranslation('pageBlockTimes')
  const toast = useToast()
  const service = useService()
  const history = useHistory()
  const [localMethod, setLocal] = useState('')
  const { state }: any = history.location
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const [name, setName] = useState('')
  const [specialist, setSpecialist] = useState<SelectType[]>([])
  const [weeksDay, setWeeksDay] = useState<SelectTypeWeek[]>([])
  const [startTime, setStartTime] = useState('')
  const [finishTime, setFinishTime] = useState('')
  const [initHour, setInitHour] = useState('')
  const [finishHour, setFinishHour] = useState('')
  const [selectedMode, setSelectedMode] = useState<'time' | 'days'>('time')
  const optionsMode = [
    { value: 'time', label: `${t('options.1')}` },
    { value: 'days', label: `${t('options.2')}` }
  ]
  const optionsDays = [
    { value: '1', label: `${t('optionsDays.1')}` },
    { value: '2', label: `${t('optionsDays.2')}` },
    { value: '3', label: `${t('optionsDays.3')}` },
    { value: '4', label: `${t('optionsDays.4')}` },
    { value: '5', label: `${t('optionsDays.5')}` },
    { value: '6', label: `${t('optionsDays.6')}` },
    { value: '7', label: `${t('optionsDays.7')}` }
  ]

  console.log(localMethod)

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  useEffect(() => {
    if (selectedMode === 'time') {
      setInitHour('')
      setFinishHour('')
      setWeeksDay([])
    } else {
      setStartTime('')
      setFinishTime('')
    }
  }, [selectedMode])

  useEffect(() => {
    if (localMethod === 'update') {
      if (state?.week_day !== null) {
        setSelectedMode('days')
      }

      if (state?.name !== undefined) {
        setName(state.name)
      }

      if (state?.start_time) {
        setStartTime(state?.start_time?.substring(0, 16))
      }

      if (state?.finish_time) {
        setFinishTime(state?.finish_time?.substring(0, 16))
      }

      if (state?.specialists) {
        const formatSpecialist = state.specialists?.map((user: any) => {
          return {
            value: user?.id,
            label: user?.social_name
          }
        })

        setSpecialist(formatSpecialist)
      }

      if (state?.week_day !== null) {
        const weekArray: string[] = state?.week_day?.split(',')
        console.log(weekArray)

        if (weekArray.length > 0) {
          const weekDefault = weekArray
          const weekValues = optionsDays
          const weekSelectReturn = weekDefault.map((item) =>
            weekValues.find((week) => week.value === item)
          )

          const weekRealValues: any = weekSelectReturn.filter(Boolean)

          console.log(weekRealValues)

          if (weekRealValues[0] !== undefined) {
            setWeeksDay(weekRealValues)
          }
        }
      }

      if (state?.recurrent_finish_time !== null) {
        setFinishHour(state?.recurrent_finish_time)
      }

      if (state?.recurrent_start_time !== null) {
        setInitHour(state?.recurrent_start_time)
      }
    }
  }, [localMethod])

  const handleCreateOrUpdateBlock: SubmitHandler<FormData> = async (values) => {
    let isReturn = false
    const { description, repeat } = values

    if (specialist?.length === 0) {
      toast({
        duration: 3000,
        status: 'warning',
        position: 'top',
        title: 'Selecione ao menos um especialista'
      })

      return
    }

    if (selectedMode === 'time' && startTime === '') {
      toast({
        duration: 3000,
        status: 'warning',
        position: 'top',
        title: 'Selecione a data hora inicial'
      })

      return
    }

    if (selectedMode === 'time' && finishTime === '') {
      toast({
        duration: 3000,
        status: 'warning',
        position: 'top',
        title: 'Selecione a data hora final'
      })

      return
    }

    if (selectedMode === 'days') {
      const initValue = Number(remaskCaractersAll(initHour))
      const endValue = Number(remaskCaractersAll(finishHour))

      if (initValue > endValue) {
        isReturn = true
      }
    }

    if (isReturn) {
      toast({
        duration: 3000,
        status: 'warning',
        position: 'top',
        title: 'Revise as Horas, horário inválido'
      })

      return
    }

    const specialists = specialist?.map((user) => {
      return {
        id: user?.value
      }
    })

    const days = weeksDay.map((day) => {
      return day.value
    })

    const payload = {
      name,
      description: description === '' ? null : description,
      start_time: startTime === '' ? null : startTime,
      finish_time: finishTime === '' ? null : finishTime,
      recurrent_start_time: initHour === '' ? null : initHour,
      recurrent_finish_time: finishHour === '' ? null : finishHour,
      specialists,
      week_day: days?.length > 0 ? days?.join(',') : null,
      expand_recurrent_days: repeat,
      is_recurrent: repeat
    }

    if (localMethod === 'create') {
      await service.createRequest(payload)
    } else {
      const { id } = state

      await service.updateRequest(payload, id)
    }
  }

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create'
                ? `${t('create.title')}`
                : `${t('updateTitle')}`}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/settings/block-times">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateOrUpdateBlock)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="25%">
                <Input
                  label={t('create.container1.i1')}
                  placeholder={t('create.container1.placeholder.name')}
                  isRequired
                  value={name}
                  name="name"
                  maxLength={40}
                  onChange={(event) =>
                    setName(event?.target.value.toUpperCase())
                  }
                />
              </Box>
              <Box w="25%">
                <Select
                  name="selectedMode"
                  options={optionsMode}
                  label="Tipo de bloqueio"
                  onChange={(event: any) => {
                    setSelectedMode(event.target.value)
                  }}
                  value={selectedMode}
                />
              </Box>
              {selectedMode === 'time' ? (
                <>
                  <Box w="25%">
                    <Input
                      label={t('create.container1.i2')}
                      name="startTime"
                      value={startTime}
                      onChange={(event) => setStartTime(event.target.value)}
                      isRequired
                      type="datetime-local"
                    />
                  </Box>
                  <Box w="25%">
                    <Input
                      label={t('create.container1.i3')}
                      name="finishTime"
                      value={finishTime}
                      onChange={(event) => setFinishTime(event.target.value)}
                      isRequired
                      type="datetime-local"
                      defaultValue={state?.finish_time?.substring(0, 16)}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box w="25%">
                    <SelectComponent
                      options={optionsDays}
                      isMulti
                      name="days"
                      value={weeksDay}
                      onChange={(e: SelectTypeWeek[]) => setWeeksDay(e)}
                      placeholder={t('create.container1.placeholder.days')}
                      title={t('options.2')}
                    />
                  </Box>
                  <Box>
                    <TextFieldControlled
                      label={t('create.container1.i6')}
                      name="initHour"
                      value={initHour}
                      onChange={(event) => setInitHour(event.target.value)}
                      isRequired
                      type="time"
                    />
                  </Box>
                  <Box>
                    <Input
                      label={t('create.container1.i7')}
                      name="finishHour"
                      value={finishHour}
                      onChange={(event) => setFinishHour(event.target.value)}
                      isRequired
                      type="time"
                    />
                  </Box>
                  <Box>
                    <Switch
                      {...register('repeat')}
                      defaultChecked={state?.is_recurrent}
                      label={t('repeat')}
                    />
                  </Box>
                </>
              )}
            </Stack>
            <Flex w="100%" my="2" gap="2" direction="column">
              <Box>
                <SelectComponent
                  title={t('create.container1.i5')}
                  options={service?.specialistsEdit?.map((user) => {
                    return {
                      value: user?.user?.id,
                      label: user?.social_name
                    }
                  })}
                  isRequired
                  placeholder={t('create.container1.placeholder.specialist')}
                  isMulti
                  isClearable
                  value={specialist}
                  onChange={(event: any) => setSpecialist(event)}
                />
              </Box>
              <Text fontSize="14px">{t('create.container1.i4')}</Text>
              <Textarea
                {...register('description')}
                placeholder={t('create.container1.placeholder.description')}
                defaultValue={state?.description || ''}
              />
            </Flex>
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}
