/* eslint-disable no-alert */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'

import FileSaver from 'file-saver'
import { Header } from '../../components/Header'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Sidebar } from '../../components/Sidebar'

import { Input } from '../../components/Form/Input'
import { Select } from '../../components/Form/Select'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { apiAuth } from '../../services/apiAuth'

type FormData = {
  is_active: boolean
  description: string
  is_special: boolean
}

type Data = {
  label: string
  value: number
}

type Specialist = {
  label: string
  value: number
}

type User = {
  id: number
  social_name: string
}

type Users = Array<User>

type SpecialistData = Array<Specialist>

export function ReportsTransfer() {
  const [t] = useTranslation('pageReportsTransfer')
  const toast = useToast()
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const optionsDefaultActions = [
    { value: 1, label: `${t('placeholder.users')}` },
    { value: 0, label: `${t('create.container2.title')}` }
  ]
  const [usersOptions, setUsersOptions] = useState<Data[]>([])
  const [users, setUsers] = useState<Data[] | null>(null)
  const [selectOption, setSelectOption] = useState(1)
  const [isDisabled, setIsDisabled] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handlerSelectUsers = (event: Data[]) => {
    if (event?.length === 0) {
      setUsers(null)
    } else {
      setUsers(event)
    }
  }

  useEffect(() => {
    setUsers(null)

    const getUsers = async () => {
      setIsDisabled(true)
      const { data } = await apiAuth.get<Users>(`clinics/transfers/users/`, {
        params: {
          is_specialist: String(selectOption)
        }
      })

      const formatUsers = data?.map((user) => {
        return {
          value: user?.id,
          label: user?.social_name
        }
      })

      setUsersOptions(formatUsers)
    }

    getUsers()
    setIsDisabled(false)

    return () => {}
  }, [selectOption])

  const handlerGenerateTransfer: SubmitHandler<FormData> = async (values) => {
    if (users === null) {
      toast({
        status: 'error',
        description:
          'Selecione o usuário(s) que deseja gerar o relatório de repasses',
        title: 'Atenção'
      })

      return
    }

    setIsLoading(true)

    try {
      const usersIds = users?.map((user) => {
        return user?.value
      })

      const { data } = await apiAuth.get(
        `clinics/transfers/specialists-reports/`,

        {
          params: {
            start_date: initialDate,
            finish_date: finalDate,
            users_ids: usersIds?.join(','),
            is_specialist: String(selectOption)
          },
          responseType: 'blob'
        }
      )

      FileSaver(data, `Repasses_${format(new Date(), 'dd/MM/yyyy')}.xlsx`)
    } catch {
      console.log('Error', console.log(users))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('create.title')}
            </Text>
          </Box>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handlerGenerateTransfer)}
      >
        <Stack spacing="4" w="100%">
          <Stack w="100%" spacing={6} direction="row">
            <Box w="320px">
              <Input
                label="Data inicial"
                isRequired
                name="inicialDate"
                value={initialDate}
                onChange={(event) => setInitialDate(event?.target.value)}
                type="date"
              />
            </Box>
            <Box w="320px">
              <Input
                label="Data final"
                isRequired
                name="finishDate"
                value={finalDate}
                onChange={(event) => setFinalDate(event?.target.value)}
                type="date"
              />
            </Box>
            <Select
              name="typeActionReport"
              options={optionsDefaultActions}
              value={selectOption}
              label="Tipo de relatório"
              onChange={(e) => setSelectOption(Number(e.target.value))}
            />
          </Stack>

          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" w="100%" spacing="6">
              <SelectComponent
                options={usersOptions}
                name="users"
                value={users}
                isDisabled={isDisabled}
                isMulti
                onChange={handlerSelectUsers}
                title={t('label.users')}
                placeholder={t('placeholder.users')}
              />
            </Stack>
            <Flex direction="column">
              <Button
                bg="blue.300"
                color="white"
                type="submit"
                isLoading={isLoading}
              >
                Gerar relatório
              </Button>
            </Flex>
          </CardContainer>
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}
