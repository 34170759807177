/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default (nre: any) => {
  nre = nre.replace(/[^\d]/g, '')
  nre = nre.substring(0, 8)

  if (nre.length > 7) {
    nre = nre.replace(/^(.{7})(\d*)/, '$1-$2')
  }

  return nre
}
