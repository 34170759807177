/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

export type Data = {
  id?: number
  name: string
  is_active?: boolean
  observation?: string
  transfers?: any
}

type Users = {
  id: number
  name: string
}

const BASE_URL = 'clinics/tags-services/'

export const useAttendance = () => {
  // terceiros
  const router = useHistory()
  const toast = useToast()

  // state
  const [users, setUsers] = useState<Users[]>([])

  useEffect(() => {
    apiAuth
      .get('users/', {
        params: {
          is_active: true
        }
      })
      .then((response) => {
        const usersFormat = response?.data?.results?.map((user: any) => {
          return {
            id: user?.user?.id,
            name: user?.social_name || user?.user?.name
          }
        })

        setUsers(usersFormat)
      })
  }, [])

  // função para criar
  async function createAttendance({
    name,
    is_active,
    observation,
    transfers
  }: Data) {
    try {
      const { data } = await apiAuth.post(BASE_URL, {
        name,
        is_active,
        observation,
        transfers: transfers || []
      })

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/services/tags')
        }, 2000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // função para editar um conveio
  async function updateAttendance({
    id,
    name,
    is_active,
    observation,
    transfers
  }: Data) {
    try {
      const res = await apiAuth.put(`${BASE_URL}${id}/`, {
        name,
        is_active,
        observation,
        transfers: transfers || []
      })

      if (res.data.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }

      setTimeout(() => {
        router.push('/services/tags')
      }, 2000)
    } catch (error) {
      console.log(error)
    }
  }

  // export
  return {
    createAttendance,
    updateAttendance,
    users
  }
}
